html,
body {
   margin:0;
   padding:0;
   height:100%;
}

body {
  background: 0 0;
  font-family: "Amazon Ember Bold", "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
  color: #fff;
  /* background: url("/images/nyc.jpg") center center no-repeat fixed; */
  background: url("/reinvent.jpg") center center no-repeat fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
   background-size: cover;
}

.App {
  min-height:100%;
  position:relative;
}

.wrapper {
  color: #fff;
}

.header {
    grid-column: 1 / 4;
    grid-row: 1;
    display: grid;
    grid-template-columns: 60% 40%;
    margin-top: 30px;
}

.header .logo {
  grid-column: 1 ;
  grid-row: 1;
}

.header .nav {
  grid-column: 2 ;
  grid-row: 1;
}

.content {
  margin: 20px;
  grid-column: 1;
  grid-row: 2;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(300px, 600px));
  grid-gap: 1em;
}

.footer {
  width: 100%;
  height:60px;   /* Height of the footer */
}

.card {
  width: auto;
  border: 2px solid #333;
  box-shadow: 3px 3px 3px #000;
  background: rgba(22,22,22,0.9);
  box-sizing: content-box;
}

.logo {
  margin: 0px 30px;
  min-width: 420px;
  font-size: 18px;
}

@media only screen and (max-width: 1000px) {
  .logo {
    margin: 0px 0px 0px 10px;
    min-width: 300px;
  }

  .logo h1 {
    font-size: 1.5rem;
  }
  
  .logo .App-logo {
    height: 60px;
  }
}

.header h1 {
  font-size: 32px;
  font-weight: bold;
  margin: 0px;
  padding-top: 10px;
}

.nav {
  margin-right: 100px;
  text-align: right;
}

.titleText {
  font-size: 1.4em;
  font-weight: 700;
  padding-top: 10px;
  display: block;
}

.title a {
  color: #fff;
  font-weight: bold;
  text-decoration: none;
}

.serving {
  font-weight: 400;
}

.title, .serving, .beerInfo {
  padding-left: 10px;
}

.percentageBar {
  background:linear-gradient(rgba(255,153,0,0.3), rgba(255, 195, 0, 0.3));
  vertical-align: bottom;
}

.percentageBarInside  {
  background:linear-gradient( #FF9900, #FFC300);
  width: 100%;
  align-self: end;
}

.percentageBar span {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  font-weight: bold;
}

.beerInfo img {
  max-width: 80px;
}

.beerInfo {
  padding: 10px;
}

.beerInfo span {
  display: block;
  text-align: center;
}

.dataBox {
  padding: 0.8em 0em;
  border: #666;
  background: #222;
  text-align: center;
  color: #fff;
  font-weight: bold;
  margin-right: 2px;
  height: 40px;
}

.dataTitles {
  font-weight: bold;
  font-size: 0.6rem;
  color: #888;
  display: block;
  text-align: center;
}

.dataValues {
  margin-top: 5px;
  font-size: 18px;
  display: block;
  
}

.mug {
  text-align: center;
}

.mug img {
  width: 80px;
  height: 100px;
}

.empty, .full {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -40px;
  text-align: center;
  overflow: hidden;
}

.empty {
  z-index: 5
}

.full {
  z-index: 6;
}

.faded {
  opacity: 0.3;
}

.hidden {
  display: none;
}

.show {
  display: block;
}

.state {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  color: #999;
  font-size: 0.8em; 
  align-self: end;
}

.chartTitle {
  text-align:center;
}


/* LAYOUT */

/* Outline of the card */

.beerCard {
  display: grid;
  grid-template-columns: 5% 25% 50% 20%;
  position: relative;
}

/* Keg Percentage Bar (Column 1) */

.percentageBar  {
  grid-column: 1;
  grid-row: 1;
  position: relative;
  width: 100%;
}

.percentageBarInside  {
  position: absolute;
  bottom: 0;
  border-top: #fff 8px solid;
  overflow: hidden;
  padding-top: 10px;
  width: 85%;
  text-shadow: -1px 0 #e69c2e, 0 1px #e69c2e, 1px 0 #e69c2e, 0 -1px #e69c2e;
  padding-left: 5px;
}


/* Active Kegerator Info (Column 2) */
.kegerator {
  grid-column: 2;
  grid-row: 1;
  position: relative;
  display: grid;
  grid-template-columns: 100%;
}

.mug {
  grid-column: 1;
  grid-row: 1;
  position: relative;
  margin-top: 10px;
}

.state {
  margin-top: 100px;
  grid-column: 1;
  grid-row: 2;
  align-self: end;
}

.flowGraph {
  grid-column: 1;
  grid-row: 3;
  align-self: end;
}

/* Beer Data (Column 3) */

.beerData {
  grid-column: 3;
  grid-row: 1;
  display: grid;
  grid-template-columns: 33% 67%;
  box-shadow: 5px 0px 0px #000;
}

.title {
  grid-column: 1 / 3;
  grid-row: 1;
}

.beerInfo {
  grid-column: 1;
  grid-row: 2;
}

.dataDetails {
  grid-column: 2;
  grid-row: 2;
  padding-top: 6px;
  display: grid;
  grid-template-columns: 33% 33% 33%;
}

.soundGraph {
  grid-column: 1 / 3;
  grid-row: 3;
  align-self: end;
}

/* Beer Map (Column 4) */

.beerMap {
  grid-column: 4;
  grid-row: 1;
  overflow: hidden;
  margin-left: 2px solid #444;
}

/* Navbar */

ul.navbar {
  padding: 0px;
  margin: 0px;
}

ul.navbar li {
  display: inline-block;
  list-style: none;
  font-size: 1em;
  padding: 10px;
}

ul.navbar .divider {
  border-right: 1px solid #444;
}

.button.button-primary {
  background: #ec7211;
  color: #fff;
  border-color: #ec7211;
  position: relative;
  text-decoration: none;
}

.button {
  background: #fff;
  color: #545b64;
  position: relative;
  text-decoration: none;
  border-radius: 2px;
  border: 1px solid #545b64;
  padding: 4px 20px;
  font-weight: 700;
  letter-spacing: .25px;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
  line-height: 20px;
}

.footer {
  font-size: 0.8em;
  color: #666;
  font-style: italic;
}

.bottomMessage {
  grid-column: 1;
  grid-row: 1;
  font-size: 14px;
  margin-left: 30px;
}

.twitter {
  grid-column: 2;
  grid-row: 1;
  text-align: center;
  font-size: 14px;
}

.location {
  grid-column: 3;
  grid-row: 1;
  text-align: right;
  margin-right: 30px;
}

.location h3 {
  font-size: 18px;
  margin: 0px;
  font-weight: bold;
}